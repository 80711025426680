
import './App.css';
import Navbar from "./components/Navbar"
import Middle from "./components/Middle"
import "./components/CSS/style.css"

function App() {
  return (
   <>
   <Navbar/>
   <Middle/>
   
   </>
  );
}

export default App;
